import React from "react";
import { Card, CardBody } from "reactstrap";
import SvgIconsSet from "./SvgIconsSet";

const ServiceCard = (props) => {
  return (
    <Card className="card-software-solution">
      <div className="card-software-solution-img-top">
        <img src={props.image} alt={props.alt} />
      </div>
      <CardBody>
        <div class="card-title">{props.title}</div>
        <p class="card-text">{props.description}</p>
        {/* <a href={props.link} class="btn btn-primary btn-icon-button">
          <span className="btn-text">Learn more</span>
          <span className="btn-icon">
            <SvgIconsSet.SideArrow />
          </span>
        </a> */}
      </CardBody>
    </Card>
  );
};

export default ServiceCard;
